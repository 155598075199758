// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cash-reinvesting-js": () => import("./../../../src/pages/cash-reinvesting.js" /* webpackChunkName: "component---src-pages-cash-reinvesting-js" */),
  "component---src-pages-cash-reinvesting-qt-js": () => import("./../../../src/pages/cash-reinvesting-QT.js" /* webpackChunkName: "component---src-pages-cash-reinvesting-qt-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joe-hogue-js": () => import("./../../../src/pages/joe-hogue.js" /* webpackChunkName: "component---src-pages-joe-hogue-js" */),
  "component---src-pages-moneygeek-js": () => import("./../../../src/pages/moneygeek.js" /* webpackChunkName: "component---src-pages-moneygeek-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-portfolio-rebalancing-js": () => import("./../../../src/pages/portfolio-rebalancing.js" /* webpackChunkName: "component---src-pages-portfolio-rebalancing-js" */),
  "component---src-pages-portfolio-rebalancing-qt-js": () => import("./../../../src/pages/portfolio-rebalancing-QT.js" /* webpackChunkName: "component---src-pages-portfolio-rebalancing-qt-js" */),
  "component---src-pages-portfolio-tracking-js": () => import("./../../../src/pages/portfolio-tracking.js" /* webpackChunkName: "component---src-pages-portfolio-tracking-js" */),
  "component---src-pages-portfolio-tracking-qt-js": () => import("./../../../src/pages/portfolio-tracking-QT.js" /* webpackChunkName: "component---src-pages-portfolio-tracking-qt-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-questrade-js": () => import("./../../../src/pages/questrade.js" /* webpackChunkName: "component---src-pages-questrade-js" */),
  "component---src-pages-questrade-portfolio-tracker-js": () => import("./../../../src/pages/questrade-portfolio-tracker.js" /* webpackChunkName: "component---src-pages-questrade-portfolio-tracker-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-landing-new-page-js": () => import("./../../../src/templates/landing-new-page.js" /* webpackChunkName: "component---src-templates-landing-new-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-moneygeek-js": () => import("./../../../src/templates/moneygeek.js" /* webpackChunkName: "component---src-templates-moneygeek-js" */),
  "component---src-templates-tutorial-js": () => import("./../../../src/templates/tutorial.js" /* webpackChunkName: "component---src-templates-tutorial-js" */)
}

